import { useLogin } from '@/stores/states';

export default defineNuxtRouteMiddleware((to, from) => {
  
 const  uselogin = useLogin();
  // const token = useCookie('token'); // get token from cookies

  const isAuthRoute = to.path === '/login' || to.path === '/registro';

  if (uselogin.token && isAuthRoute ) {
    return navigateTo('/');
  }
  if(!uselogin.token && !isAuthRoute) {
    return navigateTo('/login');
  }
  // if token doesn't exist redirect to log in
  /* 
  if (!uselogin.token && to?.name !== 'login') {
    abortNavigation();
    return navigateTo('/login');
  } 
  */
})
